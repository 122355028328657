import React, { useEffect, useState } from "react";
import Loader from "../../component/loader/Loader";
import { MdDelete } from "react-icons/md";
import { FaArrowRight, FaExternalLinkAlt, FaInfoCircle } from "react-icons/fa";
import { FaFileContract } from "react-icons/fa6";
import YesNoModal from "../../component/YesNoModal";
import { createWebSocketConnection } from "../../utils/websocket";

// Country and currency mapping
const countryCurrencyMap = {
    US: "USD",
    India: "INR",
    Bangladesh: "BDT",
    UK: "GBP",
    Canada: "CAD",
    Australia: "AUD",
    // Add more countries as needed
};

// Reverse the map to map currency to country
const currencyCountryMap = Object.keys(countryCurrencyMap).reduce((acc, country) => {
    const currency = countryCurrencyMap[country];
    acc[currency] = country;
    return acc;
}, {});

const updateCurrencyInDatabase = async (enquiryId, currency) => {
    try {
        const response = await fetch('https://wishgeekstechserve.com/api/submit-enquiry', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ _id: enquiryId, currency }),
        });

        if (!response.ok) {
            throw new Error('Failed to update currency in the database');
        }

        const result = await response.json();
        console.log(`Currency updated for enquiry ${enquiryId}:`, result);
    } catch (error) {
        console.error('Error updating currency:', error);
    }
};

const Users = () => {
    const [enquiriesData, setEnquiriesData] = useState([]);
    const [loading, setLoading] = useState(true);
    // Loader to be used inside buttons
    const [smallLoader, setSmallLoader] = useState(false);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [contactNo, setContactNo] = useState("");
    const [description, setDescription] = useState("");
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    // New state variables for name and email
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");

    const [totalAmount, setTotalAmount] = useState("");
    const [contractAmount, setContractAmount] = useState("");
    const [currency, setCurrency] = useState("");

    // Delete enquiry YesNoModal
    const [showYesNoModal, setShowYesNoModal] = useState(false);

    // filter by status
    const [selectedStatus, setSelectedStatus] = useState("all");

    const [paymentLink, setPaymentLink] = useState(null); // Store contract payment link
    const [remainingPaymentLink, setRemainingPaymentLink] = useState(null); // Store remaining payment link
    const [userAddress, setUserAddress] = useState("");
    const [userCity, setUserCity] = useState("");
    const [userPostalCode, setUserPostalCode] = useState("");
    const [userCountry, setUserCountry] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Get user token from storage or session
                const token = localStorage.getItem("token") || sessionStorage.getItem("token");
                const response = await fetch("https://wishgeekstechserve.com/api/submit-enquiry", {
                    method: "GET",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch initial data");
                }
                const data = await response.json();
                const formattedData = await Promise.all(
                    data.enquiries.map(async (enquiry) => {
                        const country = enquiry.pricingEnquiryDetailsId?.currentCountry || '';
                        const currency = countryCurrencyMap[country] || "USD"; // Default to USD

                        // Update currency in the database
                        // await updateCurrencyInDatabase(enquiry._id, currency);

                        // Extract shipping address fields
                        const shippingAddress = enquiry.shippingAddress || {};
                        const { address = "", city = "", postalCode = "", country: shippingCountry = "" } = shippingAddress;

                        return {
                            ...enquiry,
                            totalAmount: enquiry.pricingEnquiryDetailsId?.totalAmount || '',
                            currentCountry: country,
                            currency,
                            address,
                            city,
                            postalCode,
                            country: shippingCountry,
                        };
                    })
                );

                setEnquiriesData(formattedData);
            } catch (error) {
                console.error("Error fetching initial data:", error);
            } finally {
                setLoading(false);
            }
        };

        const handleWebSocketMessage = ({ type, content }) => {
            if (type === "newEnquiry") {
                setEnquiriesData((prevData) => [...prevData, content]);

            } else if (type === "updatedEnquiry") {
                setEnquiriesData((prevData) =>
                    prevData.map((enquiry) =>
                        enquiry._id === content._id ? content : enquiry
                    )
                );
            } else if (type === "deletedEnquiry") {
                setEnquiriesData((prevData) =>
                    prevData.filter((enquiry) => enquiry._id !== content.id)
                );
            }
        };

        fetchData();
        const cleanup = createWebSocketConnection(handleWebSocketMessage);

        return cleanup; // Close WebSocket connection on unmount
    }, []);

    // Filter enquiries data based on selected status
    const filteredEnquiries = selectedStatus === "all"
        ? enquiriesData
        : enquiriesData.filter((data) => data.status === selectedStatus);

    if (loading) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <Loader />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const openModal = (user) => {
        setSelectedEnquiry(user); // Set the clicked user's data
        setContactNo(user.contactNo || "");
        setDescription(user.description || ""); // Set description
        setUserName(`${user.firstName} ${user.lastName}`); // Set user name
        setUserEmail(user.email); // Set user email
        setTotalAmount(user.totalAmount || "");
        setContractAmount(user.contractAmount || "");
        setCurrency(user.currency || "");
        setUserAddress(user.address || "");  // Set user address in the modal
        setUserCity(user.city || "");
        setUserPostalCode(user.postalCode || "");
        setUserCountry(user.country || "");
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEnquiry(null); // Reset selected user when closing modal
        setTotalAmount("");
        setContractAmount("");
        setCurrency("");
        setUserAddress("");
        setUserCity("");
        setUserPostalCode("");
        setUserCountry("");
    };

    const handleContactNoChange = (e) => {
        setContactNo(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleTotalAmountChange = (e) => {
        setTotalAmount(e.target.value);
    };

    const handleContractAmountChange = (e) => {
        setContractAmount(e.target.value);
    };

    const handleCurrencyChange = async (e) => {
        setCurrency(e.target.value);
        await updateCurrencyInDatabase();
    };

    const handleSave = async () => {
        if (!selectedEnquiry) return;

        const updatedData = {
            _id: selectedEnquiry._id,
            contactNo,
            description,
            currency: currency,
            totalAmount: totalAmount,
            contractAmount: contractAmount,
        };

        setSmallLoader(true);
        try {
            const response = await fetch("https://wishgeekstechserve.com/api/submit-enquiry", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedData),
            });

            const result = await response.json();

            if (response.ok) {
                setEnquiriesData((prevData) =>
                    prevData.map((user) =>
                        user._id === selectedEnquiry._id ? { ...user, contactNo, description, currency, totalAmount, contractAmount } : user
                    )
                );
                alert("Enquiry details updated successfully");
            } else {
                alert(`Failed to update enquiry data: ${result.message}`);
            }
        } catch (err) {
            console.error("Error updating enquiry data:", err);
            alert("Error updating enquiry data");
        } finally {
            setSmallLoader(false);
        }
    };

    const openConfirmationModal = () => {
        setIsModalOpen(false); // Close the existing modal
        setIsConfirmationModalOpen(true); // Open the confirmation modal
    };

    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false);
        setIsModalOpen(true);
    };

    const handleConfirmSendContract = async () => {
        if (!selectedEnquiry) return;

        setSmallLoader(true);
        try {
            console.log("Sending contract with the following details:", {
                templateId: "8728a47d-9945-4054-bd73-2e2e1c15c521",
                name: userName,
                email: userEmail,
                currency: currency,
                pricingEnquiryDetailsId: selectedEnquiry.pricingEnquiryDetailsId._id,
                totalAmount: totalAmount,
                contractAmount: contractAmount,
                address: userAddress,
                city: userCity,
                postalCode: userPostalCode,
                country: userCountry,
            });

            // Step 1: Send contract request ////
            const sendDocRes = await fetch("https://wishgeekstechserve.com/api/send-contract", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    templateId: "8728a47d-9945-4054-bd73-2e2e1c15c521",
                    name: userName,
                    email: userEmail,
                    expiryDays: 60,
                    pricingEnquiryDetailsId: selectedEnquiry.pricingEnquiryDetailsId._id,
                    currency: currency,
                    totalAmount: totalAmount,
                    contractAmount: contractAmount,
                    address: userAddress,
                    city: userCity,
                    postalCode: userPostalCode,
                    country: userCountry,
                }),
            });

            if (!sendDocRes.ok) {
                const errorResponse = await sendDocRes.json();
                throw new Error(errorResponse.error || "Failed to send document. Please try again.");
            }

            const { documentId } = await sendDocRes.json();
            console.log("Document ID received:", documentId);

            if (!documentId) {
                console.error("No documentId returned from send-contract API");
                throw new Error("No documentId returned from send-contract API");
            }

            // Step 2: Create payment link
            console.log("Creating payment link with the following details:", {
                amount: contractAmount,
                currency: currency,
                description: `Payment for contract ${documentId}`,
                address: userAddress,
                city: userCity,
                postalCode: userPostalCode,
                country: userCountry,
            });

            const paymentLinkRes = await fetch("https://wishgeekstechserve.com/api/paymentRoutes", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    amount: contractAmount,
                    currency: currency,
                    description: `Payment for contract ${documentId}`,
                    address: userAddress,
                    city: userCity,
                    postalCode: userPostalCode,
                    country: userCountry,
                }),
            });

            console.log("Response from create-payment-link API:", paymentLinkRes);

            if (!paymentLinkRes.ok) {
                const errorResponse = await paymentLinkRes.json();
                throw new Error(errorResponse.error || "Failed to generate payment link. Please try again.");
            }

            const { paymentLink } = await paymentLinkRes.json();
            setPaymentLink(paymentLink); // Store the contract payment link in state

            alert(`Contract sent! Here is the payment link: ${paymentLink}`);

            // Step 3: Calculate the remaining amount
            const remainingAmount = totalAmount - contractAmount;

            // Step 4: Send updated data to the database
            const updatedData = {
                _id: selectedEnquiry._id,
                contractDocumentId: documentId,
                paymentLink: paymentLink,
                remainingAmount: remainingAmount,
                status: "proposed",
            };

            const updateResponse = await fetch("https://wishgeekstechserve.com/api/submit-enquiry", {
                method: "PATCH",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedData),
            });

            console.log("Response from submit-enquiry API:", updateResponse);

            if (!updateResponse.ok) {
                const errorResponse = await updateResponse.json();
                throw new Error(`Failed to update enquiry data: ${errorResponse.message}`);
            }

            closeConfirmationModal();
        } catch (err) {
            console.error("Error sending contract and generating payment link:", err);
            alert("Error occurred. Please try again.");
        } finally {
            setSmallLoader(false);
        }
    };

    // Handle generating the remaining payment link
    const handleGenerateRemainingPaymentLink = async () => {
        const remainingAmount = totalAmount - contractAmount;
        if (remainingAmount <= 0) {
            alert("No remaining amount to be paid.");
            return;
        }

        try {
            console.log("Generating remaining payment link with the following details:", {
                name: selectedEnquiry.firstName + " " + selectedEnquiry.lastName,
                amount: remainingAmount,
                currency: currency,
                description: `Payment for remaining amount of contract`,
                address: selectedEnquiry.address,
                city: selectedEnquiry.city,
                postalCode: selectedEnquiry.postalCode,
                country: selectedEnquiry.country,
                email: selectedEnquiry.email,
            });

            const remainingPaymentLinkRes = await fetch("https://wishgeekstechserve.com/api/paymentRoutes", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    name: selectedEnquiry.firstName + " " + selectedEnquiry.lastName,
                    amount: remainingAmount,
                    currency: currency,
                    description: `Payment for remaining amount of contract`,
                    address: selectedEnquiry.address,
                    city: selectedEnquiry.city,
                    postalCode: selectedEnquiry.postalCode,
                    country: selectedEnquiry.country,
                    email: selectedEnquiry.email,
                }),
            });

            if (!remainingPaymentLinkRes.ok) {
                const errorResponse = await remainingPaymentLinkRes.json();
                throw new Error(errorResponse.error || "Failed to generate remaining payment link. Please try again.");
            }

            const { paymentLink: remainingPaymentLink } = await remainingPaymentLinkRes.json();
            setRemainingPaymentLink(remainingPaymentLink); // Store the remaining payment link in state

            alert(`Remaining payment link: ${remainingPaymentLink}`);

            const updatedRemainingData = {
                _id: selectedEnquiry._id,
                remainingPaymentLink: remainingPaymentLink,
                remainingAmount: remainingAmount,
                status: "pending",
            };

            const remainingUpdateResponse = await fetch("https://wishgeekstechserve.com/api/submit-enquiry", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(updatedRemainingData),
            });

            console.log("Response from submit-enquiry API (remaining):", remainingUpdateResponse);

            if (!remainingUpdateResponse.ok) {
                const errorResponse = await remainingUpdateResponse.json();
                throw new Error(`Failed to update enquiry with remaining payment: ${errorResponse.message}`);
            }
        } catch (err) {
            console.error("Error generating remaining payment link:", err);
            alert("Error occurred. Please try again.");
        }
    };

    const confirmDelete = async () => {
        setSmallLoader(true);
        try {
            const response = await fetch(`https://wishgeekstechserve.com/api/submit-enquiry`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ _id: selectedEnquiry }),
            });

            if (response.ok) {
                setEnquiriesData((prevData) =>
                    prevData.filter((enquiry) => enquiry._id !== selectedEnquiry)
                );
            } else {
                console.error("Failed to delete enquiry");
            }
        } catch (error) {
            console.error("Error deleting enquiry:", error);
        } finally {
            setSmallLoader(false);
            setShowYesNoModal(false);
        }
    };

    const triggerYesNoModal = (id) => {
        setSelectedEnquiry(id);
        setShowYesNoModal(true);
    };

    const cancelDelete = () => {
        setShowYesNoModal(false);
        setSelectedEnquiry(null);
    };

    return (
        <>
            <YesNoModal
                isOpen={showYesNoModal}
                message="Are you sure you want to delete this enquiry?"
                onYes={confirmDelete}
                onNo={cancelDelete}
                isLoading={smallLoader}
            />
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                <div className="flex items-center justify-between mb-4 mt-28 border-b border-gray-300">
                    <h1 className="text-3xl font-medium text-gray-500">Enquiries</h1>
                    {/* Status filter dropdown */}
                    <div>
                        <span className="font-semibold">Filter by status: </span>
                        <select
                            className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                            value={selectedStatus}
                            onChange={(e) => setSelectedStatus(e.target.value)}
                        >
                            <option value="all">All</option>
                            <option value="enquired">Enquired</option>
                            <option value="proposed">Proposed</option>
                            <option value="purchased">Purchased</option>
                        </select>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 bg-white mb-5">
                        <thead className="bg-[#338dfb] text-white">
                            <tr>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Name
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Email
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Project Name
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Date <span className="text-[0.6rem]">{`{mm/dd/yyyy}`}</span>
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredEnquiries.length > 0 ? (
                                filteredEnquiries.map((data) => (
                                    <tr key={data._id}>
                                        <td className="px-6 py-4 text-xs border font-medium">
                                            {data.firstName} {data.lastName}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium">
                                            {data.email}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium">
                                            {data.projectName}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium">
                                            {new Date(data.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium">
                                            {data.status}
                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium flex items-center justify-around">
                                            <div
                                                className="bg-[#338dfb] text-white rounded-xl px-2 py-2 text-center cursor-pointer hover:bg-[#237de8] transition-colors duration-300"
                                                onClick={() => openModal(data)}
                                            >
                                                View <FaArrowRight className="inline" />
                                            </div>
                                            <MdDelete
                                                className="text-red-500 text-[26px] hover:text-red-700 cursor-pointer"
                                                onClick={() => triggerYesNoModal(data._id)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="px-6 py-4 text-xs text-gray-500 text-center">
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Preview Modal */}
            {isModalOpen && selectedEnquiry && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative">
                        <div onClick={closeModal} className="absolute top-[17px] right-[20px] cursor-pointer font-extrabold text-3xl">&times;</div>
                        <h2 className="text-xl font-semibold">Preview</h2>

                        {/* First Row: Name & Email */}
                        <div className="flex justify-between items-center mt-4">
                            <p><span className="font-semibold">Name:</span> {selectedEnquiry.firstName} {selectedEnquiry.lastName}</p>
                            <p><span className="font-semibold">Email:</span> {selectedEnquiry.email}</p>
                        </div>

                        {/* Second Row: Project Name & Date */}
                        <div className="flex justify-between items-center mt-2">
                            <p><span className="font-semibold">Project Name:</span> {selectedEnquiry.projectName}</p>
                            <p><span className="font-semibold">Date:</span> {new Date(selectedEnquiry.createdAt).toLocaleDateString()}</p>
                        </div>

                        {/* Third Row: Status & Contact Number */}
                        <div className="flex justify-between items-center mt-2">
                            <p><span className="font-semibold">Status:</span> <span className="rounded-full border border-black bg-blue-100 px-3 py-1 capitalize text-sm">{selectedEnquiry.status}</span></p>
                            <p className="flex items-center">
                                <span className="font-semibold">Contact Number:</span>
                                <input
                                    value={contactNo}
                                    onChange={handleContactNoChange}
                                    className="ml-2 border rounded-lg p-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </p>
                        </div>

                        <hr className="mt-0"></hr>

                        {/* Fourth Row: Total Amount & Contract Amount */}
                        <div className="flex items-center justify-between">
                            <div className="mr-8">
                                <label className="block text-sm font-semibold mb-1">Total Amount:</label>
                                <div className="flex space-x-2">
                                    <select
                                        className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                                        value={currency}
                                        onChange={handleCurrencyChange}
                                    >
                                        <option value="" disabled>Currency</option>
                                        <option value="INR">INR (India)</option>
                                        <option value="BDT">BDT (Bangladesh)</option>
                                        <option value="USD">USD (US)</option>
                                        <option value="GBP">GBP (UK)</option>
                                        <option value="CAD">CAD (Canada)</option>
                                        <option value="AUD">AUD (Australia)</option>
                                    </select>

                                    <input
                                        className="border rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        value={totalAmount}
                                        onChange={handleTotalAmountChange}
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-semibold mb-1">Contract Amount:</label>
                                <div className="flex space-x-2">
                                    <select
                                        className="border rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 cursor-pointer"
                                        value={currency}
                                        onChange={handleCurrencyChange}
                                    >
                                        <option value="" disabled>Currency</option>
                                        <option value="INR">INR (India)</option>
                                        <option value="BDT">BDT (Bangladesh)</option>
                                        <option value="USD">USD (US)</option>
                                        <option value="GBP">GBP (UK)</option>
                                        <option value="CAD">CAD (Canada)</option>
                                        <option value="AUD">AUD (Australia)</option>
                                    </select>

                                    <input
                                        className="border rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                        value={contractAmount}
                                        onChange={handleContractAmountChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Fifth Row: Remaining Amount */}
                        <div className="flex items-center mt-3">
                            <p className="m-0"><span className="font-bold">Remaining Amount:</span> <span className="underline font-semibold">{currency + "-" + (totalAmount - contractAmount)}</span></p>

                            {/* Only show this button after remaining amount is calculated */}
                            {totalAmount - contractAmount > 0 && (
                                <div className="flex items-center ml-4">
                                    <button
                                        className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-700 transition-colors duration-300 flex items-center"
                                        onClick={handleGenerateRemainingPaymentLink}
                                    >
                                        Send Link on Mail <FaExternalLinkAlt className="inline ml-2" />
                                    </button>
                                    <FaInfoCircle title="send remaining amount payment link on the above mentioned email" className="ml-2" />
                                </div>
                            )}
                        </div>
                        <hr></hr>

                        {/* Editable Address, City, Postal Code, and Country */}
                        <div className="mt-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-semibold mb-1">Address:</label>
                                    <input
                                        type="text"
                                        className="border rounded-lg p-2 w-full"
                                        value={selectedEnquiry.address || ''}
                                        onChange={(e) =>
                                            setSelectedEnquiry({ ...selectedEnquiry, address: e.target.value })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-semibold mb-1">City:</label>
                                    <input
                                        type="text"
                                        className="border rounded-lg p-2 w-full"
                                        value={selectedEnquiry.city || ''}
                                        onChange={(e) =>
                                            setSelectedEnquiry({ ...selectedEnquiry, city: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                                <div>
                                    <label className="block text-sm font-semibold mb-1">Postal Code:</label>
                                    <input
                                        type="text"
                                        className="border rounded-lg p-2 w-full"
                                        value={selectedEnquiry.postalCode || ''}
                                        onChange={(e) =>
                                            setSelectedEnquiry({ ...selectedEnquiry, postalCode: e.target.value })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-semibold mb-1">Country:</label>
                                    <input
                                        type="text"
                                        className="border rounded-lg p-2 w-full"
                                        value={selectedEnquiry.country || ''}
                                        onChange={(e) =>
                                            setSelectedEnquiry({ ...selectedEnquiry, country: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Description Textarea */}
                        <div className="mt-2"><span className="font-semibold">Description:</span>
                            <textarea
                                placeholder="Enter enquiry related information here..."
                                className="text-sm border rounded-lg p-3 w-full h-24 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                value={description}
                                onChange={handleDescriptionChange}
                            />
                        </div>

                        <div className="mt-4 flex justify-between">
                            <div className="flex items-center justify-center">
                                <button
                                    className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-700 transition-colors duration-300"
                                    onClick={openConfirmationModal}
                                >
                                    Prepare Contract <FaFileContract className="inline ml-2" />
                                </button>

                                {/* Logic to remove this if remaining amount has been paid */}
                                <FaInfoCircle title="Please collect remaining amount first to send contract" className="inline ml-2" />
                            </div>
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
                                onClick={handleSave}
                            >
                                {smallLoader ? (
                                    <div className="loader border-t-transparent border-solid animate-spin rounded-full border-gray-100 border-4 h-5 w-5 mr-2"></div>
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Send Contract Confirmation Modal */}
            {isConfirmationModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 relative">
                        <div className="flex items-center">
                            <div onClick={closeConfirmationModal} className="cursor-pointer font-black text-3xl mr-2 leading-none" title="Go back">&larr;</div>
                            <h2 className="text-xl font-semibold mb-0">Send Contract Confirmation</h2>
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium">Name:</label>
                            <input
                                type="text"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                className="mt-1 block w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium">Email:</label>
                            <input
                                type="email"
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                className="mt-1 block w-full border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>

                        <div className="mt-6 flex justify-between">
                            <button
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors duration-300"
                                onClick={closeConfirmationModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition-colors duration-300"
                                onClick={handleConfirmSendContract}
                            >
                                {smallLoader ? (
                                    <div className="loader border-t-transparent border-solid animate-spin rounded-full border-gray-100 border-4 h-5 w-5 mr-2"></div>
                                ) : (
                                    "Confirm Send"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>





    );
};

export default Users;